<template>
  <div class="recognition-page-shu-container">
    <RecognitionStrokePage
      :backgroundImage="bgImg"
      :strokeObj="strokeDetail"
    />
  </div>
</template>

<script>
import RecognitionStrokePage from "@/components/Course/CoursePage/RecognitionStrokePage";

export default {
  components: {
    RecognitionStrokePage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/15-Hanzi-strokes/lesson-02-shu-image.svg"),
      strokeDetail: {
        pinName: "shù",
        chineseName: "竖",
        strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-02-shu-stroke.svg"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-shu-container {
  width: 100%;
  height: 100%;
}
</style>
